<template>
  <b-modal id="streetTypeEdit" title="Изменить тип улицы" size="md" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="opened">
        <b-row>
            <b-col>
                <label>Наименование<i style="color: red;">*</i></label>
                <b-input v-model="streetType.name"/>
            </b-col>
            <b-col>
                <label>Короткое наим-е</label>
                <b-input v-model="streetType.short_name"/>
            </b-col>
        </b-row>
        <b-row> 
            <b-col style="margin-left: 15px; margin-top: 25px;" class="custom-control custom-checkbox">
                <input type="checkbox" v-model="streetType.is_active " class="custom-control-input" id="customCheck1"/>
                <label class="custom-control-label" for="customCheck1">Статус<i style="color: red;">*</i></label>
            </b-col>
        </b-row>
        <b-col cols="12" class="d-flex justify-content-between">
            <b-button
            class="mt-2 col-md-5"
            variant="primary"
            @click="changeStreet"
            >
                Добавить
            </b-button>
            <b-button
            class="mt-2 col-md-5"
            @click="clearModal"
            >
                Отмена
            </b-button>
        </b-col>
  </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    props: ['id'],
    components: {
        vSelect,
        ToastificationContent,
    },
    data() {
        return {
            streetType: {
                name: null,
                short_name: null,
                is_active: false,
                street_type_id: null,
            },
            street_types: [] 
        }
    },
    methods: {
        opened() {
            this.$http
                .get(`addresses/street-type/${this.id.id}/edit`)
                .then(res => {
                    this.streetType = res.data;
            })
            this.$http
            .get('addresses/street-types')
            .then(res =>{
                this.street_types = res.data.street_types
            })
        },
        clearModal() {
            this.$bvModal.hide('streetTypeEdit')
            this.streetType = {
                name: null,
                short_name: null,
                is_active: false,
            }
        },
        changeStreet(){
            this.$http
            .patch(`addresses/street-type/${this.id.id}` , this.streetType)
            .then(res => {
                this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: `Тип улицы успешно изменена!`,
                        },
                    })
                    this.$emit('refresh')
                    this.clearModal()
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                })
        }
    }
}
</script>


<style>

</style>