<template>
    <b-modal  
        :id="$route.name + 'CreateModal'" 
        title="Добавить тип улицы" 
        size="md" 
        hide-footer no-enforce-focus  
        :no-close-on-backdrop="true" 
    >
        <b-row>
            <b-col>
                <label>Наименование<i style="color: red;">*</i></label>
                <b-input v-model="streetType.name"/>
            </b-col>
            <b-col>
                <label>Короткое наим-е</label>
                <b-input v-model="streetType.short_name"/>
            </b-col>
        </b-row>
        <b-row> 
            <b-col style="margin-left: 15px; margin-top: 10px;" class="custom-control custom-checkbox">
                <input type="checkbox" v-model="streetType.is_active " class="custom-control-input" id="customCheck1"/>
                <label class="custom-control-label" for="customCheck1">Статус<i style="color: red;">*</i></label>
            </b-col>
        </b-row>
        <b-col cols="12" class="d-flex justify-content-between">
            <b-button
            class="mt-2 col-md-5"
            :disabled="submitButtonDisabled"
            variant="primary"
            @click="saveStreet"
            >
                Добавить
            </b-button>
            <b-button
            class="mt-2 col-md-5"
            @click="clearModal"
            >
                Отменить
            </b-button>
        </b-col>
    </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components:{
        ToastificationContent
    },
    data(){
        return{
            streetType:{
                name:null,
                short_name:null,
                is_active:false
            },
            submitButtonDisabled: false
        }
    },
    methods:{
        saveStreet(){
            this.submitButtonDisabled = true
            this.$http.post('addresses/street-type' , this.streetType)
            .then(res => {
                this.$toast({
                    component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Успех!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: `Тип улицы успешно добавлено!`,
                    },
                })
                this.clearModal()
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                })
                .finally(() => {
                    this.submitButtonDisabled = false
                })
            })
        },
        clearModal() {
            this.$bvModal.hide(this.$route.name + 'CreateModal')
            this.streetType = {
                name:null,
                short_name:null,
                is_active:false
            }
        }
    },
}
</script>