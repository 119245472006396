<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <table-street-types @editForm="editForm" :streetTypes="streetTypes" @refresh="refresh"></table-street-types>
        </div>
        <modal-type-add></modal-type-add>
        <modal-type-edit :id="id" @refresh="refresh" ></modal-type-edit>
    </div>
</template>
<script>
import modalTypeEdit from '@/views/component/Modal/ModalStreetType/modalTypeEdit.vue'
import tableStreetTypes from '@/views/component/Table/tableStreetTypes.vue'
import modalTypeAdd from '@/views/component/Modal/ModalStreetType/modalTypeAdd.vue'
export default {
    components: {
        tableStreetTypes,
        modalTypeAdd,
        modalTypeEdit
    },
    data(){
        return {
            id:'',
            streetTypes:[],
            showPreloader: false,
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        this.refresh()
    },
    methods: {
        editForm(id){
            this.id = id
            setTimeout(this.openModal, 0);
        },
        openModal(){
            this.$bvModal.show('streetTypeEdit')
        },
        refresh(){
            this.showPreloader = true
            this.$http
                .get('addresses/street-type').then(res => {
                    this.streetTypes = res.data
                    this.$store.commit('pageData/setdataCount', this.streetTypes.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                })
        },
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            if (val){
                this.$http
                .get(`${this.$route.name}`)
                .then(res =>{
                    this.streetTypes = res.data
                    this.$store.commit('pageData/setdataCount', this.streetTypes.length)
                    this.$store.commit('REFRESH_DATA', false)
            })
        }
      }
    }
}
</script>